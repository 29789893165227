<template>
  <div id="rooof-account-add">
    <rooof-account-form
      :loading="loading"
      :newsletter="true"
      class="form"
      @submit="createAccount"
    />
  </div>
</template>

<script>
import RooofAccountForm from './_components/RooofAccountForm'
import RooofAccountAPI from '@/services/api/accounts'
import RooofAPI from '@/services/api/rooof'

export default {
  name: 'RooofAccountAdd',
  components: {
    'rooof-account-form': RooofAccountForm
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    /**
     * Send create request to the API.
     *
     * @param {Object} formData
     */
    async createAccount (formData) {
      this.loading = true
      try {
        const account = await RooofAccountAPI.users.create(formData)
        if (formData.send_newsletter) {
          await RooofAPI.newsletter.create({ email: formData.email })
        }
        await RooofAccountAPI.permissions.update(account.id, formData.object_permissions)
        this.$message({
          dangerouslyUseHTMLString: true,
          message: `Account <strong>${formData.name}</strong> created`,
          type: 'success',
          duration: 3500
        })
        this.$router.push({ name: 'RooofAccounts' })
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err.toString(), details)
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
.form {
  margin-top: 1em;
  width: 60%;
}
</style>
