import serializers from '@/services/serializers/invoices'
import { request } from './index'
import { MissingPayloadError, MissingIdError } from './errors'

const InvoicesAPI = {
  customers: {
    create: data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      return request({
        method: 'post',
        url: '/invoices/customers/',
        data
      })
    }
  },
  recurring_invoices: {
    list: async (params = {}) => {
      const response = await request({
        method: 'get',
        url: '/invoices/recurring_invoices/',
        params
      })
      return response.data.results
    }
  },
  summaries: {
    run: () => {
      return request({
        method: 'post',
        url: '/invoices/run-summary/'
      })
    }
  },
  outstanding: {
    email: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/invoices/outstanding/email/',
        data
      })
      return response
    }
  },
  groups: {
    list: async (params = {}) => {
      const response = await request({
        method: 'get',
        url: '/invoices/invoicing-groups/',
        params
      })
      const data = []

      for (const group of response.data) {
        data.push(serializers.deserializeInvoicingGroup(group))
      }

      return data
    },
    retrieve: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/invoices/invoicing-groups/${id}/`
      })
      return serializers.deserializeInvoicingGroup(response.data)
    },
    create: async data => {
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: '/invoices/invoicing-groups/',
        data: serializers.serializeInvoicingGroup(data)
      })
      return serializers.deserializeInvoicingGroup(response.data)
    },
    update: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'put',
        url: `/invoices/invoicing-groups/${id}/`,
        data: serializers.serializeInvoicingGroup(data)
      })
      return serializers.deserializeInvoicingGroup(response.data)
    },
    partialUpdate: async (id, data) => {
      if (!id) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/invoices/invoicing-groups/${id}/`,
        data: serializers.serializeInvoicingGroup(data)
      })
      return serializers.deserializeInvoicingGroup(response.data)
    },
    delete: async (id, params) => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'delete',
        url: `/invoices/invoicing-groups/${id}/`,
        params
      })
      return response
    }
  },
  group_memberships: {
    list: async groupId => {
      if (!groupId) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/invoices/invoicing-groups/${groupId}/memberships/`
      })
      return response.data
    },
    retrieve: async (groupId, membershipId) => {
      if (!groupId || !membershipId) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/invoices/invoicing-groups/${groupId}/memberships/${membershipId}/`
      })
      return response.data
    },
    create: async (groupId, data) => {
      if (!groupId) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'post',
        url: `/invoices/invoicing-groups/${groupId}/memberships/`,
        data: data
      })
      return response.data
    },
    partialUpdate: async (groupId, membershipId, data) => {
      if (!groupId || !membershipId) {
        throw new MissingIdError()
      }
      if (!data) {
        throw new MissingPayloadError()
      }
      const response = await request({
        method: 'patch',
        url: `/invoices/invoicing-groups/${groupId}/memberships/${membershipId}/`,
        data: data
      })
      return response.data
    },
    delete: async (groupId, membershipId) => {
      if (!groupId) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'delete',
        url: `/invoices/invoicing-groups/${groupId}/memberships/${membershipId}/`
      })
      return response
    }
  },
  property: {
    retrieve: async id => {
      if (!id) {
        throw new MissingIdError()
      }
      const response = await request({
        method: 'get',
        url: `/invoices/property/${id}/`
      })
      return response.data
    }
  }
}

export default InvoicesAPI
