import { isEmptyString, isNull, filterEmptyValues } from '@/utils'
import { MissingRequiredValueError } from '@/utils/errors'

const serializers = {
  /**
   * De-serialize invoicing group object so it can be used in the view.
   *
   * @param {Object} group
   * @returns {Object}
   */
  deserializeInvoicingGroup (group) {
    if (!group) {
      throw new MissingRequiredValueError('group')
    }
    const groupCopy = JSON.parse(JSON.stringify(group))

    if (isNull(groupCopy.zoho_customer_id)) {
      groupCopy.zoho_customer_id = ''
    }
    if (isNull(groupCopy.zoho_recurring_invoice_id)) {
      groupCopy.zoho_recurring_invoice_id = ''
    }

    return groupCopy
  },
  /**
   * Serialize invoicing group object in preparation for request payload.
   *
   * @param {Object} group
   * @returns {Object}
   */
  serializeInvoicingGroup (group) {
    if (!group) {
      throw new MissingRequiredValueError('group')
    }
    const groupCopy = JSON.parse(JSON.stringify(group))

    // Remove empty values from arrays
    if (groupCopy.hasOwnProperty('children')) {
      groupCopy.children = filterEmptyValues(groupCopy.children)
    }
    if (groupCopy.hasOwnProperty('properties')) {
      groupCopy.properties = filterEmptyValues(groupCopy.properties)
    }

    if (isEmptyString(groupCopy.zoho_customer_id)) {
      groupCopy.zoho_customer_id = null
    }
    if (isEmptyString(groupCopy.zoho_recurring_invoice_id)) {
      groupCopy.zoho_recurring_invoice_id = null
    }

    return groupCopy
  }
}

export default serializers
